<template>
  <UserStyles>
    <v-main>
      <v-card
        class="ma-2"
        flat
      >
        <v-row>
          <v-col cols="12" md="8">
            <v-card-title>Пользователь</v-card-title>
          </v-col>
          <v-col cols="4" md="4" class="text-right">
            <router-link :to="{ name: 'UserAdminResetPassword', params: { id: user.id } }">
              <v-btn class="me-2" color="primary">Сменить пароль</v-btn>
            </router-link>
            <router-link :to="{ name: 'UserEdit', params: { id: user.id } }">
              <v-btn color="primary">Изменить</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="account-text-info">
                <span class="font-weight-medium">Роль:</span>
                {{ user.group_name }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">ФИО:</span>
                {{ user.first_name }} {{ user.last_name }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Компания: </span>
                <router-link :to="{ name: 'CompanyView', params: { id: user.company } }">{{ user.company_display }}
                </router-link>
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Email:</span>
                {{ user.email }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Номер телефона:</span>
                {{ user.phone }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Никнейм:</span>
                {{ user.username }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </UserStyles>
</template>

<script>
import account from "@/api/account";
import UserStyles from "@/views/users/UserStyles.vue";

export default {
  components: {
    UserStyles
  },
  data() {
    return {
      user: {
        id: '',
        name: '',
        city: '',
      },
    };
  },
  async created() {
    await this.fetchUser();
  },
  methods: {
    async fetchUser() {
      try {
        const userId = this.$route.params.id;
        const response = await account.getUser(userId);
        this.user = response.data;
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    },
  },
};
</script>
